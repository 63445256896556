<template>
  <div class="achievement-container">
    <div class="item" v-for="item in itemList" :key="item.articleId">
      <div class="title" @click="goDetails(item)">
        <span class="column" v-if="item.articleTypeName"
          >[{{ item.articleTypeName }}]</span
        >
        <span class="name" v-html="item.title"></span>
      </div>
      <div class="info">
        <span v-if="item.firstAuthor">{{ item.firstAuthor }}</span>
        <span v-if="item.othorAuthor">{{ item.othorAuthor }}</span>
        <span v-if="item.communicationAuthor">{{ item.communicationAuthor }}</span>
        <span v-if="item.periodical">{{ item.periodical }}</span>
        <span style="margin-left: -10px;">，</span>
        <span v-if="item.journalsYear">{{ item.journalsYear }}</span>
      </div>
      <IconButton :every="item" :curcer="item.articleId"></IconButton>
    </div>
  </div>
</template>

<script>
import IconButton from "@/components/pager/IconButton.vue";
export default {
  props: ["itemList"],
  components: {
    IconButton,
  },
  methods: {
    goDetails(item) {
      this.$emit("goDetails", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 22px;
  border-bottom: 1px dashed #d6d6d6;
  padding-bottom: 18px;
  .title {
    cursor: pointer;
    font-size: 20px;
    .column {
      color: #b6b6b6;
    }
  }
  .name {
    margin-left: 5px;
  }
  .info {
    margin: 10px 0;
    font-size: 14px;
    color: #999999;
    display: flex;
    align-items: center;
    span {
      max-width:200px;
      overflow: hidden;
      display: inline-block;
      text-overflow: ellipsis;
      margin-right: 10px;
      white-space: nowrap;
    }
  }
}
</style>

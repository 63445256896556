<template>
  <div class="achievements-container">
    <div class="achievements-side">
      <Achievement
        @requestClick="requestClick2"
        @requestAll="requestAll1"
      ></Achievement>
      <Include @requestClick="requestClick" @requestAll="requestAll2"></Include>
      <Year @requestClick="requestClick1" @requestAll="requestAll3"></Year>
    </div>
    <div class="content-container">
      <div class="resultNum">
        检测到<span>{{ listData.total }}</span
        >条结果
      </div>
      <AchievementItem
        :itemList="listData.list"
        @goDetails="goDetails"
      ></AchievementItem>
      <div class="no-results" v-show="+listData.total === 0">
        <img :src="require('@/assets/qietu/results.png')" alt="" />
        <p>暂无成果</p>
      </div>
      <Page
        v-show="listData.total > 8"
        show-total
        :total="listData.total > 8 * 300 ? 8 * 300 : +listData.total"
        :current="listData.pageNum"
        :page-size="8"
        :styles="{ textAlign: 'center', marginTop: '20px' }"
        @on-change="pageChange"
      >
      </Page>
      <Spin size="large" fix v-show="spinShow"></Spin>
    </div>
  </div>
</template>

<script>
import Year from "../components/Year.vue";
import Include from "../components/Include.vue";
import Achievement from "../components/Achievement.vue";
import AchievementItem from "@/components/pager/AchievementItem.vue";
export default {
  components: {
    Year,
    Include,
    Achievement,
    AchievementItem,
  },
  props: ["value"],
  data() {
    return {
      listData: {},
      params: {
        articleTypes: "0,1,2,3,4",
        types: "0,1,2,4",
        pageNo: 1,
        pageSize: 8,
        paramStr: this.value,
      },
      spinShow: false,
    };
  },
  created() {
    this.getSearchList(this.params);
  },
  watch: {
    value(newVal) {
      // 论文列表
      this.getSearchList({
        ...this.params,
        paramStr: newVal,
      }); // 当地址栏变化就调用接口
    },
  },
  methods: {
    async getSearchList(data) {
      this.spinShow = true;
      const resp = await this.$apis.searchServe.getSearchList(data);
      this.listData = resp.data ? resp.data : {};
      this.spinShow = false;
    },
    pageChange(page) {
      this.getSearchList({
        ...this.params,
        pageNo: page,
      });
    },
    goDetails(item) {
      let url;
      if (item.articleType === 0) {
        if (item.type === 0) {
          url = this.$router.resolve({
            path: "paperDetail",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 1) {
          url = this.$router.resolve({
            path: "conferencepapers",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 2) {
          url = this.$router.resolve({
            path: "conferenceppt",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        } else if (item.type === 4) {
          url = this.$router.resolve({
            path: "conferenceother",
            query: {
              articleType: item.articleType,
              articleId: item.articleId,
            },
          });
        }
      } else if (item.articleType === 1) {
        url = this.$router.resolve({
          path: "patentdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 2) {
        url = this.$router.resolve({
          path: "monographdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 3) {
        url = this.$router.resolve({
          path: "reportdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 4) {
        url = this.$router.resolve({
          path: "standarddetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      } else if (item.articleType === 5 && item.type === 0) {
        url = this.$router.resolve({
          path: "informationdetail",
          query: {
            articleType: item.articleType,
            articleId: item.articleId,
          },
        });
      }
      window.open(url.href, "_blank");
    },
    requestClick(item) {
      this.params.dataStyle = item.dbName;
      this.getSearchList({
        ...this.params,
      });
    },
    requestAll1() {
      this.params.articleTypes = "0,1,2,3,4";
      this.params.types = "0,1,2,4";
      delete this.params.articleType;
      delete this.params.type;
      this.getSearchList({
        ...this.params,
        paramStr: this.$route.query.paramStr,
      });
    },
    requestAll2() {
      delete this.params.dataStyle;
      this.getSearchList({
        ...this.params,
        paramStr: this.$route.query.paramStr,
      });
    },
    requestAll3() {
      delete this.params.journalsYear;
      this.getSearchList({
        ...this.params,
        paramStr: this.$route.query.paramStr,
      });
    },
    requestClick1(item) {
      this.params.journalsYear = item.name;
      this.getSearchList({
        ...this.params,
        paramStr: this.$route.query.paramStr,
      });
    },
    requestClick2(item) {
      delete this.params.articleTypes;
      delete this.params.types;
      if (item.value === "0_0") {
        this.params.type = 0;
        this.params.articleType = 0;
        this.getSearchList({
          ...this.params,
          paramStr: this.$route.query.paramStr,
        });
      } else if (item.value === "0_1") {
        this.params.types = "1,2";
        this.params.articleType = 0;
        delete this.params.type;
        this.getSearchList({
          ...this.params,
          paramStr: this.$route.query.paramStr,
        });
      } else if (item.value === "0_4") {
        this.params.type = 4;
        delete this.params.articleType;
        this.getSearchList({
          ...this.params,
          paramStr: this.$route.query.paramStr,
        });
      } else if (item.value === "1") {
        delete this.params.type;
        this.params.articleType = 1;
        this.getSearchList({
          ...this.params,
          paramStr: this.$route.query.paramStr,
        });
      } else if (item.value === "2") {
        delete this.params.type;
        this.params.articleType = 2;
        this.getSearchList({
          ...this.params,
          paramStr: this.$route.query.paramStr,
        });
      } else if (item.value === "3") {
        delete this.params.type;
        this.params.articleType = 3;
        this.getSearchList({
          ...this.params,
          paramStr: this.$route.query.paramStr,
        });
      } else if (item.value === "4") {
        delete this.params.type;
        this.params.articleType = 4;
        this.getSearchList({
          ...this.params,
          paramStr: this.$route.query.paramStr,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.achievements-container {
  width: 1440px;
  display: flex;
  .content-container {
    position: relative;
    padding: 29px;
    margin-left: 39px;
    width: 1140px;
    border: 1px dotted #999999;
    border-radius: 12px;
    background-color: #fff;
    .resultNum {
      font-size: 15px;
      color: #999999;
      font-weight: 400;
      span {
        color: #00a4ff;
      }
      margin-bottom: 14px;
    }
    .no-results {
      margin: 0 auto;
      width: 500px;
      // height: 300px;
      img {
        width: 100%;
      }
      p {
        text-align: center;
        font-size: 16px;
        color: #999999;
      }
    }
  }
}
</style>

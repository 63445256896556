<template>
  <div class="block-container" @click="handleClick">
    <div class="headPhoto">
      <img
        class="photo"
        :src="$global.imgUrl + block.headPhoto"
        v-if="block.headPhoto"
      />
      <!-- <img class="photo" v-else :src="require('@/assets/imgs/head_bg.png')" /> -->
      <span v-else class="photo img">{{ block.realName | imgFormatting}}</span>
      <div class="name">{{ block.realName }}</div>
      <div class="is-xing" v-if="block.isXing">
        <svg
          id="图层_1"
          data-name="图层 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 170 170"
        >
          <title>icon-sch</title>
          <circle class="cls-1" cx="119.88" cy="119.88" r="48.12" />
          <path
            class="cls-1"
            d="M67.19,129c3.4,28.13,31.16,38.44,31.16,38.44-9.18,1.34-18.71.52-29.89.52-35.8,0-64.81-1.86-64.81-37.66A64.78,64.78,0,0,1,35,74.8,51.26,51.26,0,0,0,68.46,87.88a31.33,31.33,0,0,0,9.17-1.17S63.79,100.9,67.19,129Z"
          />
          <circle class="cls-1" cx="68.46" cy="41.67" r="39.42" />
          <path
            class="cls-2"
            d="M89.16,122l21.28,19.13a9.81,9.81,0,0,0,14.24-1.2l27.73-35"
          />
        </svg>
      </div>
    </div>
    <ul class="icon">
      <li v-for="(icon, i) in block.honourList" :key="i">
        <img v-lazy="$global.imgUrl1 + icon.honourLogo" alt="" />
      </li>
    </ul>
    <div class="info">
      <p class="job-name" v-if="block.titleList && block.titleList.length > 0">
        职称：<span v-for="v in block.titleList" :key="v.id">{{
          v.levelThreeName
        }}</span>
      </p>
      <p
        class="job-jobDirection"
        v-if="block.subjectList && block.subjectList.length > 0"
      >
        研究领域：<span v-for="v in block.subjectList" :key="v.id">{{
          v.levelTwoName
        }}</span>
      </p>
      <p class="job-mechanism" v-if="block.unit">学者机构：{{ block.unit }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    block: {
      type: Object,
      default: () => {},
    },
  },
  filters: {
    imgFormatting(query) {
      console.log(query,'query');
      if (query === undefined || query === '') return ''
      return query.slice(query.length-2,query.length)
    },
  },
  methods: {
    handleClick() {
      let url = this.$router.resolve({
        path: "/autherdetail",
        query: {
          id: this.block.userId,
        },
      });
      window.open(url.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: #00a4ff;
}
.cls-2 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 7px;
}
.block-container {
  width: 234px;
  height: 245px;
  padding: 30px 25px;
  background: #ffffff;
  border: 1px solid #e9e9e9;
  border-radius: 16px;
  margin-right: 30px;
  margin-bottom: 30px;
  .headPhoto {
    display: flex;
    align-items: center;
    .photo {
      width: 62px;
      height: 62px;
      background: #f6f6f6;
      border-radius: 50%;
      text-align: center;
      line-height: 62px;
      margin-right: 10px;
      cursor: pointer;
    }
    .img{
      background: #1876F0;
      font-size: 28px;
      color: #fff;
    }
    .name {
      font-size: 28px;
      color: #333333;
      margin-right: 5px;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .is-xing {
      width: 17px;
      height: 17px;
    }
  }
  .icon {
    width: 100%;
    height: 17px;
    margin: 15px 0;
    display: flex;
    li {
      width: 17px;
      height: 17px;
      margin: 0 3px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .info {
    p {
      margin: 5px 0;
    }
    .job-mechanism,
    .job-jobDirection,
    .job-name {
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>

<template>
  <div class="auther-container">
    <div class="resultNum">
      检测到<span>{{ listData.total }}</span
      >条结果
    </div>
    <div class="content">
      <Block
        class="userBox"
        v-for="(item, i) in listData.list"
        :key="i"
        :block="item"
        :iconList="iconList"
      ></Block>
    </div>
    <div class="no-results" v-show="+listData.total === 0">
      <img :src="require('@/assets/qietu/results.png')" alt="">
      <p>暂无学者</p>
    </div>
    <Page
      v-show="listData.total > 10"
      :total="+listData.total"
      :current="listData.pageNum"
      show-total
      :page-size="10" 
      :styles="{textAlign: 'center',marginTop: '20px'}"
      @on-change="pageChange">
    </Page>
    <Spin size="large" fix v-show="spinShow"></Spin>
  </div>
</template>

<script>
import Block from "@/views/auther/components/Block.vue";
export default {
  components: {
    Block,
  },
  props: ["value"],
  data() {
    return {
      listData: {},
      iconList: [
        require("@/assets/imgs/icon-sch_02.png"),
        require("@/assets/imgs/icon-sch_03.png"),
        require("@/assets/imgs/icon-sch_04.png"),
        require("@/assets/imgs/icon-sch_05.png"),
        require("@/assets/imgs/icon-sch_06.png"),
        require("@/assets/imgs/icon-sch_07.png"),
        require("@/assets/imgs/icon-sch_08.png"),
        require("@/assets/imgs/icon-sch_09.png"),
      ],
      params: {
        pageNo: 1,
        pageSize: 10,
        name: this.value,
      },
      spinShow: false
    };
  },
  created() {
    this.getScholarList(this.params);
  },
  watch: {
    value(newVal) {
      this.getScholarList({
        ...this.params,
        name: newVal,
      }); // 当搜索数据变化就调用接口
    },
  },
  methods: {
    async getScholarList(data) {
      this.spinShow = true
      const resp = await this.$apis.searchServe.searchScholarList(data);
      this.listData = resp.data ? resp.data : {};
      this.spinShow = false
    },
    pageChange(page) {
      this.getScholarList({
        ...this.params,
        pageNo: page
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.auther-container {
  position: relative;
}
.resultNum {
  font-size: 15px;
  color: #999999;
  font-weight: 400;
  span {
    color: #00a4ff;
  }
  margin-bottom: 20px;
}
.content {
  display: flex;
  flex-wrap: wrap;
  .userBox {
    width: 264px;
    &:nth-child(5n) {
      margin-right: 0px;
    }
  }
  // justify-content: space-between;
}
.no-results {
  margin: 0 auto;
  width: 500px;
  // height: 300px;
  img {
    width: 100%;
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #999999;
  }
}
</style>

<template>
  <div class="search-container">
    <div class="search-header">
      <Input
        class="search-inp"
        search
        placeholder="检索学者、学术圈、学术成果"
        :value="$route.query.paramStr"
        @on-search="handleSearch"
      />
      <input type="text" class="elHidden" />
      <div class="search-nav">
        <span :class="active === 1 ? 'active' : ''" @click="to(1)">学者</span>
        <span :class="active === 2 ? 'active' : ''" @click="to(2)">成果</span>
        <span :class="active === 3 ? 'active' : ''" @click="to(3)">学术圈</span>
        <span :class="active === 4 ? 'active' : ''" @click="to(4)">讨论</span>
        <!-- <router-link
          :to="{ path: '/auther', query: { paramStr: $route.query.paramStr } }"
          >学者</router-link
        >
        <router-link
          :to="{
            path: '/achievements',
            query: { paramStr: $route.query.paramStr },
          }"
          >成果</router-link
        >
        <router-link
          :to="{ path: '/circle', query: { paramStr: $route.query.paramStr } }"
          >学术圈</router-link
        >
        <router-link
          :to="{ path: '/discuss', query: { paramStr: $route.query.paramStr } }"
          >讨论</router-link
        > -->
      </div>
    </div>
    <div class="search-content">
      <Auther v-if="active === 1" :value="value" />
      <Achievements v-if="active === 2" :value="value" />
      <StuCircle v-if="active === 3" :value="value" />
      <Discuss v-if="active === 4" :value="value" />
    </div>
  </div>
</template>

<script>
import Achievements from "./views/Achievements.vue";
import Auther from "./views/Auther.vue";
import StuCircle from "./views/Circle.vue";
import Discuss from "./views/Discuss.vue";
export default {
  components: {
    Achievements,
    Auther,
    StuCircle,
    Discuss
  },
  data() {
    return {
      active: 1,
      value: this.$route.query.paramStr ? this.$route.query.paramStr : '',
    }
  },
  created() {
    console.log(this.$route,'this.$route');
  },
  methods: {
    to(index) {
      this.active = index
    },
    handleSearch(e) {
      this.value = e
    },
  },
};
</script>

<style lang="scss" scoped>
.search-container {
  .search-header {
    width: 100%;
    height: 241px;
    background-color: #fff;
    text-align: center;
    border-bottom: 1px solid #f6f6f6;
    margin: 0 auto;
    .search-inp {
      width: 840px;
      height: 70px;
      margin: 90px 0 50px;
      &::v-deep .ivu-input {
        height: 70px;
        background-color: #fefefe;
        border: 1px solid #00a4ff;
        box-shadow: 0px 1px 8px 0px rgba(20, 122, 182, 0.4);
        border-radius: 35px;
        font-size: 18px;
        padding-left: 35px;
        padding-right: 70px;
      }
      &::v-deep .ivu-input-icon {
        font-size: 3em;
        line-height: 70px;
        right: 30px;
        color: #006db2;
      }
    }
    .search-nav {
      width: 640px;
      height: 32px;
      line-height: 23px;
      margin: 0 auto;
      span {
        display: inline-block;
        width: 160px;
        font-size: 18px;
        color: #333;
        cursor: pointer;
      }
    }
    .elHidden {
      width: 0;
      opacity: 0;
    }
  }
  .search-content {
    width: 1440px;
    margin: 60px auto;
  }
}
.active {
  color: #00a4ff !important;
  &::after {
    content: "";
    display: block;
    width: 160px;
    height: 8px;
    background-image: url("~@/assets/imgs/xz.png");
    background-size: 100% 100%;
  }
}
</style>

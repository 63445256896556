<template>
  <div class="discuss-container">
    <div class="resultNum">
      检测到<span>{{ listData.total }}</span
      >条结果
    </div>
    <div class="content">
      <div class="talk" v-for="(item, i) in listData.list" :key="i">
        <div class="title" @click="goDiscuss(item)">{{ item.title }}</div>
        <div class="contents">{{ item.content }}</div>
      </div>
    </div>
    <div class="no-results" v-show="+listData.total === 0">
      <img :src="require('@/assets/qietu/results.png')" alt="">
      <p>暂无讨论</p>
    </div>
    <Page
      v-show="listData.total > 10"
      :total="+listData.total"
      :current="listData.pageNum"
      show-total
      :page-size="10"
      :styles="{ textAlign: 'center', marginTop: '20px' }"
      @on-change="pageChange"
    >
    </Page>
    <Spin size="large" fix v-show="spinShow"></Spin>
  </div>
</template>

<script>
export default {
  props: ["value"],
  data() {
    return {
      listData: {},
      params: {
        title: this.value,
        pageNum: 1,
        pageSize: 10,
      },
      spinShow: false,
    };
  },
  created() {
    this.fuzzySearchCircleDiscuss(this.params);
  },
  watch: {
    value(newVal) {
      this.fuzzySearchCircleDiscuss({
        ...this.params,
        title: newVal,
      }); // 当搜索数据变化就调用接口
    },
  },
  methods: {
    goDiscuss(v) {
      let url = this.$router.resolve({
        path: "/discussDetails",
        query: {
          id: v.id,
        },
      });
      window.open(url.href, "_blank");
    },
    async fuzzySearchCircleDiscuss(data) {
      this.spinShow = true;
      const resp = await this.$apis.circleServe.fuzzySearchCircleDiscuss(data);
      this.listData = resp.data ? resp.data : {};
      this.spinShow = false;
    },
    pageChange(page) {
      this.fuzzySearchCircleTitle({
        ...this.params,
        pageNum: page,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.discuss-container {
  position: relative;
}
.resultNum {
  font-size: 15px;
  color: #999999;
  font-weight: 400;
  span {
    color: #00a4ff;
  }
  margin-bottom: 20px;
}
.no-results {
  margin: 0 auto;
  width: 500px;
  // height: 300px;
  img {
    width: 100%;
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #999999;
  }
}
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .talk {
    position: relative;
    width: 700px;
    height: 297px;
    padding: 40px 30px;
    margin-bottom: 40px;
    background: linear-gradient(90deg, #006db2, #04c8dc, #006db2);
    background: #f9f9f9;
    border: 1px solid #999999;
    box-shadow: 0px 0px 10px 0px rgba(11, 46, 88, 0.1);
    border-radius: 12px;
    &::before {
      position: absolute;
      left: 0;
      top: -1px;
      content: "";
      display: block;
      width: 700px;
      height: 4px;
      background-image: url("~@/assets/imgs/squre.png");
      background-size: 100% 100%;
    }
    .title {
      font-size: 20px;
      color: #00a4ff;
      cursor: pointer;
    }
    .contents {
      margin-top: 42px;
      font-size: 16px;
      color: #333333;
      width: 639px;
      // height: 154px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      overflow: hidden;
      text-align: justify;
    }
  }
}
</style>

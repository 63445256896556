<template>
  <div class="side-box-container">
    <h2 class="title">发表年份</h2>
    <div
      class="box"
      v-for="(v, i) in comYear"
      ref="sideLi"
      :key="i"
      :class="{ active: i === index }"
      @click="handleClick(v, i)"
    >
      <span class="name">{{ v.name }}</span>
      <span class="num">({{ v.num }})</span>
    </div>
    <p class="more" @click="handleMore">更多</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      index: -1,
      yearList: [],
      achievementList: [],
      curPage: 1,
    };
  },
  created() {
    this.getArticleCount({
      articleTypes: "0,1,2,3,4",
      types: "0,1,2,4",
      paramStr: this.$route.query.paramStr,
    });
    this.getYearList();
  },
  watch: {
    "$route.query"(newVal) {
      // 论文列表
      this.getArticleCount({
        articleTypes: "0,1,2,3,4",
        types: "0,1,2,4",
        paramStr: newVal.paramStr,
      }); // 当地址栏变化就调用接口
    },
  },
  computed: {
    comYear() {
      let result = this.yearList.map((item) => {
        let res;
        for (let i = 0; i < this.achievementList.length; i++) {
          const v = this.achievementList[i];
          if (item === v.value) {
            res = {
              name: item,
              num: v.score,
            };
          }
        }
        if (!res) {
          res = {
            name: item,
            num: 0,
          };
        }
        return res;
      });
      return result;
    },
  },
  methods: {
    handleClick(item, i) {
      this.index = i;
      let element = this.$refs.sideLi[this.index];
      if (element.classList.contains("active")) {
        element.classList.remove("active");
        this.$emit("requestAll");
      } else {
        element.classList.add("active");
        this.$emit("requestClick", item);
      }
    },
    async getYearList(data) {
      const resp = await this.$apis.searchServe.getYearList(data);
      this.yearList.push(...resp.yearSet);
    },
    async getArticleCount(data) {
      const resp = await this.$apis.searchServe.getYearCount(data);
      this.achievementList = resp.data;
    },
    handleMore() {
      this.getYearList({
        pageNo: ++this.curPage,
        pageSize: 5,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.side-box-container {
  width: 260px;
  //   height: 390px;
  background: #ffffff;
  border: 1px dotted #999999;
  border-radius: 6px;
  padding: 35px 30px;
  position: relative;
  margin-bottom: 30px;
  user-select: none;
  &::after {
    position: absolute;
    left: -1px;
    top: -1px;
    content: "";
    display: block;
    width: 260px;
    height: 4px;
    background-image: url("~@/assets/imgs/squre.png");
    background-size: 100% 100%;
  }
  .title {
    font-size: 22px;
    font-weight: 400;
    color: #00a4ff;
    border-bottom: 28px;
  }
  .box {
    font-size: 18px;
    color: #333333;
    margin-top: 15px;
    cursor: pointer;
    &.active {
      color: #00a4ff;
    }
    .num {
      margin-left: 10px;
    }
  }
  .more {
    margin-top: 10px;
    cursor: pointer;
  }
}
</style>

<template>
  <div class="circle-container">
    <div class="resultNum">
      检测到<span>{{ listData.total }}</span
      >条结果
    </div>
    <div class="content">
      <ul class="card-container">
        <li v-for="(item, i) in listData.list" :key="i" class="item">
          <div class="top">
            <div class="title">
              <p class="subject" @click="goDetails(item)">
                {{ item.circle.title }}
              </p>
              <p class="auther">
                {{ item.circle.scholarNum }}位学者关注·{{
                  item.circle.postNum
                }}个帖子
              </p>
            </div>
            <Button
              class="follow active"
              v-if="item.status"
              @click="focusCircle(item.circle.id, true)"
              v-throttleClick="2000"
            >
              已关注
            </Button>
            <Button class="follow" @click="focusCircle(item.circle.id)" v-else v-throttleClick="2000">
              关注+
            </Button>
          </div>
          <div class="content">{{ item.circle.detail }}</div>
        </li>
      </ul>
    </div>
    <div class="no-results" v-show="+listData.total === 0">
      <img :src="require('@/assets/qietu/results.png')" alt="">
      <p>暂无学术圈</p>
    </div>
    <Page
      v-show="listData.total > 10"
      :total="+listData.total"
      :current="listData.pageNum"
      show-total
      :page-size="10"
      :styles="{ textAlign: 'center', marginTop: '20px' }"
      @on-change="pageChange"
    >
    </Page>
    <Spin size="large" fix v-show="spinShow"></Spin>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["value"],
  data() {
    return {
      listData: {},
      params: {
        title: this.value,
        pageNum: 1,
        pageSize: 10,
      },
      spinShow: false,
    };
  },
  created() {
    this.fuzzySearchCircleTitle(this.params);
  },
  computed: {
    ...mapState(["hasLogin"]),
  },
  watch: {
    value(newVal) {
      this.fuzzySearchCircleTitle({
        ...this.params,
        title: newVal,
      }); // 当搜索数据变化就调用接口
    },
  },
  methods: {
    goDetails(item) {
      let url = this.$router.resolve({
        path: "/circledetails",
        query: {
          id: item.circle.id,
        },
      });
      window.open(url.href, "_blank");
    },
    // 关注按钮
    async focusCircle(id, status = false) {
      if (this.hasLogin) {
        // 1 关注 0 取消关注
        let data = {
          attentionId: id,
          type: status ? 0 : 1,
        };
        const resp = await this.$apis.circleServe.onFocus(data);
        if (resp.resultDesc === "成功!") {
          this.fuzzySearchCircleTitle(this.params);
          // this.$Message.success(resp.resultDesc);
          this.$Message.success(status ? '取消关注成功' : '关注成功');
        } else {
          this.$Message.error("关注失败");
        }
      } else {
        this.$store.commit("showAlert", true);
      }
    },
    async fuzzySearchCircleTitle(data) {
      this.spinShow = true;
      const resp = await this.$apis.circleServe.fuzzySearchCircleTitle(data);
      this.listData = resp.data ? resp.data : {};
      this.spinShow = false;
    },
    pageChange(page) {
      this.fuzzySearchCircleTitle({
        ...this.params,
        pageNum: page,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.circle-container {
  position: relative;
}
.resultNum {
  font-size: 15px;
  color: #999999;
  font-weight: 400;
  span {
    color: #00a4ff;
  }
  margin-bottom: 20px;
}
.card-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .item {
    position: relative;
    padding: 30px;
    width: 446px;
    // height: 221px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(11, 46, 88, 0.1);
    border-radius: 8px;
    margin: 0 30px 40px 0;
    &::before {
      position: absolute;
      left: 0;
      top: -1px;
      content: "";
      display: block;
      width: 446px;
      height: 4px;
      background-image: url("~@/assets/imgs/squre.png");
      background-size: 100% 100%;
    }
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        .subject {
          font-size: 26px;
          color: #00a4ff;
          cursor: pointer;
        }
        .auther {
          font-size: 14px;
          color: #999999;
        }
      }
      .follow {
        width: 98px;
        height: 38px;
        background: #00a4ff;
        border-radius: 19px;
        text-align: center;
        line-height: 38px;
        color: #fff;
        cursor: pointer;
        &.active {
          border: 1px solid #00a4ff;
          color: #00a4ff;
          background-color: transparent;
        }
      }
    }
    .content {
      width: 384px;
      margin: 25px 0;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      font-size: 16px;
      color: #999999;
    }
  }
}
.no-results {
  margin: 0 auto;
  width: 500px;
  // height: 300px;
  img {
    width: 100%;
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #999999;
  }
}
</style>
